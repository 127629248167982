import { Component, OnInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CatalogosService, CommonService, LoginService } from 'src/app/services/index.services';
import * as Constants from '../../appconstants';

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.css']
})
export class BitacoraComponent implements OnInit, OnDestroy {

  registros: any = [];

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(
    private catalogosService: CatalogosService,
    private commonService: CommonService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {

    if (!this.loginService.validate()) {
      this.loginService.logout();
    }

    this.dtOptions = {
      scrollX: true,
      pageLength: 5,
      order: [[ 0, "desc" ]],
      lengthMenu: [[5, 25, 50, 100], [5, 25, 50, 100]],
      language: Constants.DT_LANGUAGE
    };

    this.commonService.showLoader();
    this.catalogosService.getBitacora().subscribe(res => {
      this.registros = res.body;
      this.dtTrigger.next();
      this.commonService.closeLoader();
    }, error => {
      this.commonService.showError(error);
    });

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
