import { Component, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faEdit, faPlus, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CatalogosService, CommonService, LoginService, UserService } from 'src/app/services/index.services';
import Swal from 'sweetalert2';
import * as Constants from '../../appconstants';


@Component({
  selector: 'app-catalogos',
  templateUrl: './catalogos.component.html',
  styleUrls: ['./catalogos.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CatalogosComponent implements OnInit, OnDestroy {

  faEdit = faEdit;
  faUnlock = faUnlock;
  faPlus = faPlus;

  userForm: FormGroup;
  corredorForm: FormGroup;
  exclusividadForm: FormGroup;

  userModalAccion: string;
  corredorModalAccion: string;
  exclusividadModalAccion: string;

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings[] = [];
  dtTrigger: Subject<any>[] = [new Subject(), new Subject(), new Subject()];

  usuarios: any = [];
  corredores: any;
  clases: any;
  estatus: any;
  alcaldias: any;
  exclusividades: any;
  multiusuarios: any;

  constructor(
    private catalogosService: CatalogosService,
    private userService: UserService,
    private loginService: LoginService,
    private commonService: CommonService,
    private formBuilder: FormBuilder) {

  }

  ngOnInit() {

    var cmcomponent = this;
    $('a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
      cmcomponent.refreshTables();
    });

    this.userForm = this.formBuilder.group({
      id: new FormControl('', []),
      email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      nombre: new FormControl('', [Validators.required]),
      apellidoPaterno: new FormControl('', [Validators.required]),
      apellidoMaterno: new FormControl(''),
      role: new FormControl('', [Validators.required]),
      activo: new FormControl(true, [Validators.required]),
    });

    this.corredorForm = this.formBuilder.group({
      id: new FormControl('', []),
      nombre: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
      activo: new FormControl(true, [Validators.required]),
    });

    this.exclusividadForm = this.formBuilder.group({
      id: new FormControl('', []),
      nombre: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
      activo: new FormControl(true, [Validators.required]),
    });

    if (!this.loginService.validate()) {
      this.loginService.logout();
    }

    if (this.commonService.catalogosCargados) {
      this.llenaTablas();
    } else {
      this.commonService.broadcaster.subscribe(message => {
        if (message.channel == "cargaCatalogos") {
          if (message.body.result) {
            this.llenaTablas();
          } else {
            this.commonService.showError(message.body.error);
          }
        }
      },
      error => {
        this.commonService.showError(error);
      });
    }
  }

  llenaTablas() {
    this.updateCatalogos();
    this.commonService.delay(100).then(any => {
      this.dtElements.forEach((dtElement, index: number) => {
        this.dtOptions[index] = this.buildDtOptions();
        this.dtTrigger[index].next();
        dtElement.dtInstance.then((dtInstance: any) => {
          dtInstance.destroy();
          this.dtTrigger[index].next();
        });
      });
    });
  }

  refreshTables() {
    this.updateCatalogos();
    this.dtElements.forEach((dtElement, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger[index].next();
      });
    });
  }

  updateCatalogos() {
    this.usuarios = this.catalogosService.usuarios;
    this.corredores = this.catalogosService.corredores;
    this.exclusividades = this.catalogosService.exclusividades;
  }

  private buildDtOptions(): DataTables.Settings {
    return {
      scrollX: true,
      pageLength: 5,
      lengthMenu: [[5, 25, 50, 100], [5, 25, 50, 100]],
      language: Constants.DT_LANGUAGE
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger[0].unsubscribe();
    this.dtTrigger[1].unsubscribe();
    this.dtTrigger[2].unsubscribe();
  }

  closeUserModal() {
    this.commonService.closeModal('userModal', this.userForm);
  }

  openUserModal() {
    this.commonService.openModal('userModal');
  }

  getRole(role) {
    let dictionary = { 'ADMIN': 'Administrador', 'EDIT': 'Edición', 'READ': 'Consulta' };
    return dictionary[role];
  }

  saveUser() {
    this.commonService.showLoader();
    let formObj = this.userForm.getRawValue(); // {name: '', description: ''}
    if (this.userForm.get("id").value) {

      this.catalogosService.updateUsuario(formObj).subscribe(resp => {
        this.catalogosService.usuarios = resp;
        this.refreshTables();
        this.closeUserModal();
        this.commonService.showSuccess();
      },
        error => {
          this.commonService.showError(error);
        });
    } else {

      this.catalogosService.createUsuario(formObj).subscribe(resp => {
        this.catalogosService.usuarios = resp;
        this.refreshTables();
        this.closeUserModal();
        this.commonService.showSuccess();
      },
        error => {
          this.commonService.showError(error);
        });
    }
  }

  newUser() {
    this.userModalAccion = "Nuevo Usuario";
    this.userForm.reset();
    this.userForm.get("activo").setValue(true);
    this.userForm.get("role").setValue("");
    this.openUserModal();
  }

  editUser(usuario) {
    this.userModalAccion = "Editar Usuario";
    this.userForm.setValue(usuario);
    this.openUserModal();
  }

  resetUserPass(email) {
    Swal.fire({
      title: "Reset de password",
      text: "Se enviará un correo al usuario con un nuevo password",
      type: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: '#DB2A25',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Si, reset',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this.commonService.showLoader();
        this.userService.resetPassword({ "email": email }).subscribe(resp => {
          this.commonService.showSuccess();
        },
          error => {
            this.commonService.showError(error);
          });
      }
    });
  }

  closeCorredorModal() {
    this.commonService.closeModal('corredorModal', this.corredorForm);
  }

  openCorredorModal() {
    this.commonService.openModal('corredorModal');
  }

  saveCorredor() {
    this.commonService.showLoader();
    let formObj = this.corredorForm.getRawValue();
    if (this.corredorForm.get("id").value) {

      this.catalogosService.updateCorredor(formObj).subscribe(resp => {
        this.catalogosService.corredores = resp;
        this.refreshTables();
        this.closeCorredorModal();
        this.commonService.showSuccess();
      },
        error => {
          this.commonService.showError(error);
        });
    } else {

      this.catalogosService.createCorredor(formObj).subscribe(resp => {
        this.catalogosService.corredores = resp;
        this.refreshTables();
        this.closeCorredorModal();
        this.commonService.showSuccess();
      },
        error => {
          this.commonService.showError(error);
        });
    }
  }

  newCorredor() {
    this.corredorModalAccion = "Nuevo Corredor";
    this.corredorForm.reset();
    this.corredorForm.get("activo").setValue(true);
    this.openCorredorModal();
  }

  editCorredor(corredor) {
    this.corredorModalAccion = "Editar Corredor";
    this.corredorForm.setValue(corredor);
    this.openCorredorModal();
  }

  closeExclusividadModal() {
    this.commonService.closeModal('exclusividadModal', this.exclusividadForm);
  }

  openExclusividadModal() {
    this.commonService.openModal('exclusividadModal');
  }

  saveExclusividad() {
    this.commonService.showLoader();
    let formObj = this.exclusividadForm.getRawValue();
    if (this.exclusividadForm.get("id").value) {

      this.catalogosService.updateExclusividad(formObj).subscribe(resp => {
        this.catalogosService.exclusividades = resp;
        this.refreshTables();
        this.closeExclusividadModal();
        this.commonService.showSuccess();
      },
        error => {
          this.commonService.showError(error);
        });
    } else {

      this.catalogosService.createExclusividad(formObj).subscribe(resp => {
        this.catalogosService.exclusividades = resp;
        this.refreshTables();
        this.closeExclusividadModal();
        this.commonService.showSuccess();
      },
        error => {
          this.commonService.showError(error);
        });
    }
  }

  newExclusividad() {
    this.exclusividadModalAccion = "Nueva Exclusividad";
    this.exclusividadForm.reset();
    this.exclusividadForm.get("activo").setValue(true);
    this.openExclusividadModal();
  }

  editExclusividad(exclusividad) {
    this.exclusividadModalAccion = "Editar Exclusividad";
    this.exclusividadForm.setValue(exclusividad);
    this.openExclusividadModal();
  }


}
