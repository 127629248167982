import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { faDownload, faEdit, faEnvelope, faFileArchive, faIndustry, faMapMarked, faPhone, faPlus, faSave, faTrash, faUndo, faUserTag, faImage } from '@fortawesome/free-solid-svg-icons';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CatalogosService, CommonService, PropiedadesService, LoginService, FilesService } from 'src/app/services/index.services';
import Swal from 'sweetalert2';
import * as Constants from '../../appconstants';
import { Propiedad, Piso, Nota, Contacto, S3File } from './Propiedad';

@Component({
  selector: 'app-edicion',
  templateUrl: './edicion.component.html',
  styleUrls: ['./edicion.component.css']
})
export class EdicionComponent implements OnInit {

  @ViewChild('foto', null)
  fotoFile: ElementRef;

  faImage = faImage;
  faTrash = faTrash;
  faSave = faSave;
  faUndo = faUndo;
  faPlus = faPlus;
  faEdit = faEdit;
  faDownload = faDownload;
  faFileArchive = faFileArchive;
  faPhone = faPhone;
  faMapMarked = faMapMarked;
  faEnvelope = faEnvelope;
  faIndustry = faIndustry;
  faUserTag = faUserTag;

  propiedades: Propiedad[] = [];
  propiedad: Propiedad = new Propiedad();
  editando: boolean = false;
  imgURL: any;
  imageData: string = "";
  imageName: string = "";
  unsavedChanges = false;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  corredores: any;
  clases: any;
  estatus: any;
  alcaldias: any;
  exclusividades: any;
  multiusuarios: any;

  constructor(
    private catalogosService: CatalogosService,
    private propiedadesService: PropiedadesService,
    private commonService: CommonService,
    private loginService: LoginService,
    public filesService: FilesService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    let me = this;

    $("input").on('change', function() {
      me.unsavedChanges = true;
    });

    if (!this.loginService.validate()) {
      this.loginService.logout();
    }

    this.dtOptions = {
      scrollX: true,
      pageLength: 5,
      order: [[1, "asc"]],
      lengthMenu: [[5, 25, 50, 100], [5, 25, 50, 100]],
      language: Constants.DT_LANGUAGE
    };

    this.cargaPropiedades();

    if (this.commonService.catalogosCargados) {
      this.cargaCatalogos();
    } else {
      this.commonService.broadcaster.subscribe(message => {
        if (message.channel == "cargaCatalogos") {
          if (message.body.result) {
            this.cargaCatalogos();
          } else {
            this.commonService.showError(message.body.error);
          }
        }
      },
        error => {
          this.commonService.showError(error);
        });
    }
  }

  formChanged() {
    this.unsavedChanges = true;
  }

  cargaPropiedades() {
    this.commonService.showLoader();
    this.propiedadesService.getAll().subscribe(res => {
      this.propiedades = res;
      this.rerender();
      this.editando = false;
      this.commonService.closeLoader();
    }, error => {
      this.commonService.showError(error);
    });
  }

  rerender(): void {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.clear();
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  clavePad(id) {
    return ('000' + id).substr(-4);
  }

  toJSONLocal() {
    var local = new Date();
    return local.toJSON().slice(0, 10) + ' ' + local.getHours() + ':' + local.getMinutes();
  }

  cargaCatalogos() {
    this.corredores = this.catalogosService.corredores;
    this.clases = this.catalogosService.clases;
    this.estatus = this.catalogosService.estatus;
    this.alcaldias = this.catalogosService.alcaldias;
    this.exclusividades = this.catalogosService.exclusividades;
    this.multiusuarios = this.catalogosService.multiusuarios;
  }

  editPropiedad(propiedad: Propiedad) {
    this.commonService.showLoader();
    this.propiedadesService.get(propiedad.id).subscribe((res: Propiedad) => {
      this.commonService.closeLoader();
      this.propiedad = res;
      this.editando = true;
      this.loadFiles();
    },
      error => {
        this.commonService.showError(error);
      });
  }

  loadFiles() {
    this.filesService.getFiles(this.clavePad(this.propiedad.id), "FOTOS").subscribe(
      (res: any) => {
        this.propiedad.fotos = res.files;
      },
      error => {
        this.commonService.showError(error);
      });

    this.filesService.getFiles(this.clavePad(this.propiedad.id), "DOCS").subscribe(
      (res: any) => {
        this.propiedad.adjuntos = res.files;
      },
      error => {
        this.commonService.showError(error);
      });
  }

  newPropiedad() {
    this.resetFile();
    this.propiedad = new Propiedad();
    this.editando = true;
  }

  save() {
    this.commonService.showLoader();
    if (this.propiedad.id) {
      this.propiedadesService.updatePropiedad(this.propiedad, this.imageName, this.imageData).subscribe((res: Propiedad) => {
        this.commonService.closeLoader();
        this.propiedad = res;
        this.resetFile();
        this.loadFiles();
        this.commonService.showSuccess();
        this.unsavedChanges = false;
      },
        error => {
          this.commonService.showError(error);
        });
    } else {
      this.propiedadesService.newPropiedad(this.propiedad, this.imageName, this.imageData).subscribe((res: Propiedad) => {
        this.commonService.closeLoader();
        this.propiedad = res;
        this.resetFile();
        this.commonService.showSuccess();
        this.unsavedChanges = false;
      },
        error => {
          this.commonService.showError(error);
        });
    }
  }

  cancel() {
    if(this.unsavedChanges) {
      Swal.fire({
        title: '¿Desea regresar?',
        text: "Cualquier cambio realizado no guardado se perderá",
        type: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: '#DB2A25',
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          this.resetFile();
          this.propiedad = new Propiedad();
          this.cargaPropiedades();
        }
      });
    } else {
      this.resetFile();
      this.propiedad = new Propiedad();
      this.cargaPropiedades();
    }
  }

  preview(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      this.imageData = (reader.result as string).split(',')[1]
    }
  }

  previewFile(id: any, tipo: string, fileName: string) {
    this.commonService.showLoader();
    this.filesService.preview(this.clavePad(id), tipo, fileName).subscribe(
      (response: any) => {
        this.commonService.closeLoader();
        let base64Image = "data:image/png;base64, " + response.data;
        Swal.fire({
          width: 900,
          confirmButtonText: 'Cerrar',
          confirmButtonColor: '#DB2A25',
          html: "<img src='" + base64Image + "' style='width:100%;'>",
        })
      },
      error => {
        this.commonService.showError(error);
      });
  }

  downloadFile(id: any, tipo: string, fileName: string) {
    this.commonService.showLoader();
    this.filesService.download(this.clavePad(id), tipo, fileName).subscribe(
      (response: any) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(response, fileName);
        } else {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.parentNode.removeChild(downloadLink);
        }
        this.commonService.closeLoader();
      },
      error => {
        this.commonService.showError(error);
      });
  }

  resetFile() {
    this.imgURL = undefined;
    this.fotoFile.nativeElement.value = '';
    this.imageData = "";
    this.imageName = "";
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.imageName = file.name;
      this.preview(file);
      // this.uploadForm.get('adjunto').setValue(file);
    }
  }

  nuevaNota() {
    if (!this.propiedad.notas) {
      this.propiedad.notas = [];
    }
    Swal.fire({
      title: 'Nueva nota',
      html:
        '<div class="row">' +
        '<div class="col-12"><div class="input-group input-group mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Nota</span></div><input id="nota-input" type="text" class="form-control"></div></div>' +
        '</div>',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Crear',
      confirmButtonColor: '#DB2A25',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let data = (document.getElementById('nota-input') as HTMLInputElement).value;
        if (data) {
          return data;
        } else {
          Swal.showValidationMessage("La nota no puede estar vacía");
        }

      },
    }).then((result) => {
      if (result.value) {
        this.propiedad.notas.push({ id: null, fecha: this.toJSONLocal(), nota: result.value });
      }
    });
  }

  delNota(nota: Nota) {
    for (var i = 0; i < this.propiedad.notas.length; i++) {
      if (this.propiedad.notas[i].nota === nota.nota) {
        this.propiedad.notas.splice(i, 1);
        break;
      }
    }
  }

  nuevoPiso() {
    if (!this.propiedad.pisos) {
      this.propiedad.pisos = [];
    }
    Swal.fire({
      title: 'Nuevo nivel',
      width: 900,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Crear',
      confirmButtonColor: '#DB2A25',
      cancelButtonText: 'Cancelar',
      html:
        '<div class="row">' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Nivel</span></div><input id="piso-input1" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Util total</span></div><input id="piso-input2" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Rentable total</span></div><input id="piso-input3" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Util disponible</span></div><input id="piso-input4" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Rentable disponible</span></div><input id="piso-input5" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Renta USD</span></div><input id="piso-input6" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Venta USD</span></div><input id="piso-input7" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Renta MXN</span></div><input id="piso-input8" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Venta MXN</span></div><input id="piso-input9" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Mtto.</span></div><input id="piso-input10" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Usuarios</span></div><input id="piso-input11" type="text" class="form-control"></div></div>' +

        '</div>',
      focusConfirm: false,
      preConfirm: () => {
        let nivel = (document.getElementById('piso-input1') as HTMLInputElement).value;
        let exist: boolean = false;
        for (var i = 0; i < this.propiedad.pisos.length; i++) {
          if (this.propiedad.pisos[i].nivel == nivel) {
            exist = true;
            break;
          }
        }
        if (exist) {
          Swal.showValidationMessage("El nivel ya existe");
        } else {
          if (nivel) {
            return [
              (document.getElementById('piso-input1') as HTMLInputElement).value,
              (document.getElementById('piso-input2') as HTMLInputElement).value,
              (document.getElementById('piso-input3') as HTMLInputElement).value,
              (document.getElementById('piso-input4') as HTMLInputElement).value,
              (document.getElementById('piso-input5') as HTMLInputElement).value,
              (document.getElementById('piso-input6') as HTMLInputElement).value,
              (document.getElementById('piso-input7') as HTMLInputElement).value,
              (document.getElementById('piso-input8') as HTMLInputElement).value,
              (document.getElementById('piso-input9') as HTMLInputElement).value,
              (document.getElementById('piso-input10') as HTMLInputElement).value,
              (document.getElementById('piso-input11') as HTMLInputElement).value
            ]
          } else {
            Swal.showValidationMessage("Por lo menos debe de especificar el nivel");
          }
        }
      }
    }).then((result) => {
      if (result.value) {
        this.propiedad.pisos.push({
          id: null,
          nivel: result.value[0],
          totalUtilizable: result.value[1],
          totalRentable: result.value[2],
          disponibleUtilizable: result.value[3],
          disponibleRentable: result.value[4],
          rentaUsd: result.value[5],
          ventaUsd: result.value[6],
          rentaMxn: result.value[7],
          ventaMxn: result.value[8],
          mantenimiento: result.value[9],
          usuarios: result.value[10],
        });
      }
    });
  }

  editPiso(piso:Piso) {
    Swal.fire({
      title: 'Editando nivel',
      width: 900,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Editar',
      confirmButtonColor: '#DB2A25',
      cancelButtonText: 'Cancelar',
      html:
        '<div class="row">' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Nivel</span></div><input value="' + piso.nivel + '" id="piso-input1" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Util total</span></div><input value="' + piso.totalUtilizable + '" id="piso-input2" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Rentable total</span></div><input value="' + piso.totalRentable + '" id="piso-input3" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Util disponible</span></div><input value="' + piso.disponibleUtilizable + '" id="piso-input4" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Rentable disponible</span></div><input value="' + piso.disponibleRentable + '" id="piso-input5" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Renta USD</span></div><input value="' + piso.rentaUsd + '" id="piso-input6" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Venta USD</span></div><input value="' + piso.ventaUsd + '" id="piso-input7" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Renta MXN</span></div><input value="' + piso.rentaMxn + '" id="piso-input8" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Venta MXN</span></div><input value="' + piso.ventaMxn + '" id="piso-input9" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Mtto.</span></div><input value="' + piso.mantenimiento + '" id="piso-input10" type="number" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Usuarios</span></div><input value="' + piso.usuarios + '" id="piso-input11" type="text" class="form-control"></div></div>' +
        '</div>',
      focusConfirm: false,
      preConfirm: () => {
        let nivel = (document.getElementById('piso-input1') as HTMLInputElement).value;
        let exist: boolean = false;
        if (nivel != piso.nivel) {
          for (var i = 0; i < this.propiedad.pisos.length; i++) {
            if (this.propiedad.pisos[i].nivel == nivel) {
              exist = true;
              break;
            }
          }
        }
        if (exist) {
          Swal.showValidationMessage("El nivel ya existe");
        } else {
          if (nivel) {
            return [
              (document.getElementById('piso-input1') as HTMLInputElement).value,
              (document.getElementById('piso-input2') as HTMLInputElement).value,
              (document.getElementById('piso-input3') as HTMLInputElement).value,
              (document.getElementById('piso-input4') as HTMLInputElement).value,
              (document.getElementById('piso-input5') as HTMLInputElement).value,
              (document.getElementById('piso-input6') as HTMLInputElement).value,
              (document.getElementById('piso-input7') as HTMLInputElement).value,
              (document.getElementById('piso-input8') as HTMLInputElement).value,
              (document.getElementById('piso-input9') as HTMLInputElement).value,
              (document.getElementById('piso-input10') as HTMLInputElement).value,
              (document.getElementById('piso-input11') as HTMLInputElement).value
            ]
          } else {
            Swal.showValidationMessage("Por lo menos debe de especificar el nivel");
          }
        }
      }
    }).then((result) => {
      if (result.value) {
        for (var i = 0; i < this.propiedad.pisos.length; i++) {
          if ((piso.id && this.propiedad.pisos[i].id === piso.id) || (!piso.id && this.propiedad.pisos[i].nivel === piso.nivel)) {
            this.propiedad.pisos[i] = {
              id: this.propiedad.pisos[i].id,
              nivel: result.value[0],
              totalUtilizable: result.value[1],
              totalRentable: result.value[2],
              disponibleUtilizable: result.value[3],
              disponibleRentable: result.value[4],
              rentaUsd: result.value[5],
              ventaUsd: result.value[6],
              rentaMxn: result.value[7],
              ventaMxn: result.value[8],
              mantenimiento: result.value[9],
              usuarios: result.value[10],
            };
            break;
          }
        }
      }
    });
  }

  delPiso(piso: Piso) {
    for (var i = 0; i < this.propiedad.pisos.length; i++) {
      if (this.propiedad.pisos[i].nivel === piso.nivel) {
        this.propiedad.pisos.splice(i, 1);
        break;
      }
    }
  }

  nuevoContacto() {
    if (!this.propiedad.contacto) {
      this.propiedad.contacto = [];
    }
    Swal.fire({
      title: 'Nuevo nivel',
      width: 900,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Crear',
      confirmButtonColor: '#DB2A25',
      cancelButtonText: 'Cancelar',
      html:
        '<div class="row">' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Nombre</span></div><input id="contacto-input1" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Telefonos</span></div><input id="contacto-input2" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Email</span></div><input id="contacto-input3" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Dirección</span></div><input id="contacto-input4" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Colonia</span></div><input id="contacto-input5" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Empresa</span></div><input id="contacto-input6" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Puesto</span></div><input id="contacto-input7" type="text" class="form-control"></div></div>' +
        '</div>',
      focusConfirm: false,
      preConfirm: () => {
        let nombre = (document.getElementById('contacto-input1') as HTMLInputElement).value;
        let exist: boolean = false;
        for (var i = 0; i < this.propiedad.contacto.length; i++) {
          if (this.propiedad.contacto[i].nombre == nombre) {
            exist = true;
            break;
          }
        }
        if (exist) {
          Swal.showValidationMessage("El contacto ya existe");
        } else {
          if (nombre) {
            return [
              (document.getElementById('contacto-input1') as HTMLInputElement).value,
              (document.getElementById('contacto-input2') as HTMLInputElement).value,
              (document.getElementById('contacto-input3') as HTMLInputElement).value,
              (document.getElementById('contacto-input4') as HTMLInputElement).value,
              (document.getElementById('contacto-input5') as HTMLInputElement).value,
              (document.getElementById('contacto-input6') as HTMLInputElement).value,
              (document.getElementById('contacto-input7') as HTMLInputElement).value,
            ]
          } else {
            Swal.showValidationMessage("Por lo menos debe de especificar el nombre");
          }
        }
      }
    }).then((result) => {
      if (result.value) {
        this.propiedad.contacto.push({
          id: null,
          nombre: result.value[0],
          telefonos: result.value[1],
          email: result.value[2],
          direccion: result.value[3],
          colonia: result.value[4],
          empresa: result.value[5],
          puesto: result.value[6]
        });
      }
    });
  }

  editContacto(contacto: Contacto) {
    Swal.fire({
      title: 'Editando contacto',
      width: 900,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Editar',
      confirmButtonColor: '#DB2A25',
      cancelButtonText: 'Cancelar',
      html:
        '<div class="row">' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Nombre</span></div><input value="' + contacto.nombre + '" id="contacto-input1" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Telefonos</span></div><input value="' + contacto.telefonos + '" id="contacto-input2" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Email</span></div><input value="' + contacto.email + '" id="contacto-input3" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Dirección</span></div><input value="' + contacto.direccion + '" id="contacto-input4" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Colonia</span></div><input value="' + contacto.colonia + '" id="contacto-input5" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Empresa</span></div><input value="' + contacto.empresa + '" id="contacto-input6" type="text" class="form-control"></div></div>' +
        '<div class="col-md-6 col-sm-12"><div class="input-group input-group-sm mb-3"><div class="input-group-prepend">' +
        '<span class="input-group-text">Puesto</span></div><input value="' + contacto.puesto + '" id="contacto-input7" type="text" class="form-control"></div></div>' +
        '</div>',
      focusConfirm: false,
      preConfirm: () => {
        let nombre = (document.getElementById('contacto-input1') as HTMLInputElement).value;
        let exist: boolean = false;
        if (nombre != contacto.nombre) {
          for (var i = 0; i < this.propiedad.contacto.length; i++) {
            if (this.propiedad.contacto[i].nombre == nombre) {
              exist = true;
              break;
            }
          }
        }

        if (exist) {
          Swal.showValidationMessage("El contacto ya existe");
        } else {
          if (nombre) {
            return [
              (document.getElementById('contacto-input1') as HTMLInputElement).value,
              (document.getElementById('contacto-input2') as HTMLInputElement).value,
              (document.getElementById('contacto-input3') as HTMLInputElement).value,
              (document.getElementById('contacto-input4') as HTMLInputElement).value,
              (document.getElementById('contacto-input5') as HTMLInputElement).value,
              (document.getElementById('contacto-input6') as HTMLInputElement).value,
              (document.getElementById('contacto-input7') as HTMLInputElement).value,
            ]
          } else {
            Swal.showValidationMessage("Por lo menos debe de especificar el nombre");
          }
        }
      }
    }).then((result) => {
      if (result.value) {
        for (var i = 0; i < this.propiedad.contacto.length; i++) {
          if (this.propiedad.contacto[i].nombre === contacto.nombre) {
            this.propiedad.contacto[i] = {
              id: null,
              nombre: result.value[0],
              telefonos: result.value[1],
              email: result.value[2],
              direccion: result.value[3],
              colonia: result.value[4],
              empresa: result.value[5],
              puesto: result.value[6]
            };
            break;
          }
        }
      }
    });
  }

  delContacto(contacto: Contacto) {
    for (var i = 0; i < this.propiedad.contacto.length; i++)
      if (this.propiedad.contacto[i].nombre === contacto.nombre) {
        this.propiedad.contacto.splice(i, 1);
        break;
      }
  }

  nuevoFile(tipo: string) {
    Swal.fire({
      title: 'Subir ' + tipo,
      input: 'file',
      inputAttributes: {
        'accept': tipo == 'FOTOS' ? 'image/*' : '*/*',
        'aria-label': 'Seleccione un archivo'
      },
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Crear',
      confirmButtonColor: '#DB2A25',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: (file) => {
        if (file) {
          return file;
        } else {
          Swal.showValidationMessage("Debe de seleccionar una imagen");
        }
      },
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        this.commonService.showLoader();
        formData.append('file', result.value);
        formData.append('id', this.clavePad(this.propiedad.id));
        formData.append('tipo', tipo);
        this.filesService.upload(formData).subscribe(
          (res) => {
            this.commonService.closeLoader();
            this.loadFiles();
          }, (err) => {
            this.commonService.showError(err);
          }
        );
      }
    });
  }

  delFile(file: S3File, tipo: string) {
    this.commonService.showLoader();
    this.filesService.delete(this.clavePad(this.propiedad.id), tipo, file.fileName).subscribe(
      (res) => {
        this.commonService.closeLoader();
        this.loadFiles();
      }, (err) => {
        this.commonService.showError(err);
      }
    );
  }
}