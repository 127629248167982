import { Component, OnInit } from '@angular/core';
import { CommonService, ReportesService, LoginService } from 'src/app/services/index.services';
import { Propiedad } from '../edicion/Propiedad';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {

  propiedades: Propiedad[] = [];

  constructor(
    private loginService: LoginService,
    private commonService: CommonService,
    private reportesService: ReportesService
  ) { }

  ngOnInit() {
    if (!this.loginService.validate()) {
      this.loginService.logout();
    }
    this.propiedades = JSON.parse(sessionStorage.getItem("propiedades"));

    this.commonService.broadcaster.subscribe(message => {
      if (message.channel == "reportes") {
        let props: Propiedad[] = JSON.parse(sessionStorage.getItem("propiedades"));
        this.propiedades = props;
      }
    },
      error => {
        this.commonService.showError(error);
      });
  }

  clavePad(id) {
    return ('000' + id).substr(-4);
  }

  removePropiedad(id) {
    this.reportesService.removePropiedad(id);
    this.propiedades = JSON.parse(sessionStorage.getItem("propiedades"));
    this.commonService.broadcast("reportes", { result: true, action: 'remove' });
  }

  getPropiedad(id: number, nombre: string, formato: string) {
    this.commonService.showLoader();
    this.reportesService.getPropiedad(id, formato).subscribe(
      (response: any) => {
        let fileName = id + "-" + nombre.replace(' ', '_').replace('.', '') + '.' + formato;
        this.commonService.showLoader();
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(response, fileName);
        } else {
          let dataType = "application/vnd.ms-excel";
          if(formato == 'pdf') {
            dataType = "application/pdf";
          }
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.parentNode.removeChild(downloadLink);
        }
        this.commonService.closeLoader();
      },
      error => {
        var reader = new FileReader();
        reader.onload = function () {
          Swal.fire("Ocurrió un error", JSON.parse(reader.result.toString()).message, "error");
        }
        reader.readAsText(error.error);
        
      });
  }

  getPropiedades(formato: string) {
    this.commonService.showLoader();
    this.reportesService.getPropiedades(this.propiedades, formato).subscribe(
      (response: any) => {
        let fileName = 'multiple.' + formato;
        this.commonService.showLoader();
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(response, fileName);
        } else {
          let dataType = "application/vnd.ms-excel";
          if(formato == 'pdf') {
            dataType = "application/pdf";
          }
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.parentNode.removeChild(downloadLink);
        }
        this.commonService.closeLoader();
      },
      error => {
        var reader = new FileReader();
        reader.onload = function () {
          Swal.fire("Ocurrió un error", JSON.parse(reader.result.toString()).message, "error");
        }
        reader.readAsText(error.error);
        
      });
  }

  sendPropiedad(id: number, formato: string, correos: string) {
    this.commonService.showLoader();
    this.reportesService.sendPropiedad(id, formato, correos).subscribe(
      (response: any) => {
        this.commonService.closeLoader();
      },
      error => {
        this.commonService.showError(error);
      });
  }

  sendPropiedades(formato: string, correos: string) {
    this.commonService.showLoader();
    this.reportesService.sendPropiedades(this.propiedades, formato, correos).subscribe(
      (response: any) => {
        this.commonService.closeLoader();
      },
      error => {
        this.commonService.showError(error);
      });
  }

  getReporte() {
    Swal.fire({
      title: 'Descarga de reporte',
      html:
        '<div class="row">' +
        '<div class="col-12">' +
        '<div class="form-group">' +
        '<label for="formato">Formato del reporte</label>' +
        '<select id="formato" class="form-control">' +
        '<option value="pdf">PDF</option>' +
        '<option value="xls">Excel</option>' +
        '</select>' +
        '</div>' +
        '</div>' +
        '</div>',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Descargar',
      confirmButtonColor: '#DB2A25',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: () => {
        return (document.getElementById('formato') as HTMLInputElement).value;
      },
    }).then((result) => {
      if (result.value) {
        if (this.propiedades.length == 1) {
          this.getPropiedad(this.propiedades[0].id, this.propiedades[0].nombre, result.value);
        } else {
          this.getPropiedades(result.value);
        }
      }
    });
  }

  sendReporte() {
    Swal.fire({
      title: 'Envío de reporte',
      html:
        '<div class="row">' +
        '<div class="col-12">' +
        '<div class="form-group">' +
        '<label for="formato">Formato del reporte</label>' +
        '<select id="formato" class="form-control">' +
        '<option value="pdf">PDF</option>' +
        '<option value="xls">Excel</option>' +
        '</select>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '<div class="row">' +
        '<div class="col-12">' +
        '<div class="form-group">' +
        '<label for="email">Direcciones de envío (separadas por coma)</label>' +
        '<input id="email" type="text" class="form-control">' +
        '</div>' +
        '</div>' +
        '</div>',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      confirmButtonColor: '#DB2A25',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: () => {
        return [
          (document.getElementById('formato') as HTMLInputElement).value,
          (document.getElementById('email') as HTMLInputElement).value,
        ]
      },
    }).then((result) => {
      if (result.value) {
        if (this.propiedades.length == 1) {
          this.sendPropiedad(this.propiedades[0].id, result.value[0], result.value[1]);
        } else {
          this.sendPropiedades(result.value[0], result.value[1]);
        }
      }
    });
  }

  getComparativo() {
    this.commonService.showLoader();
    this.reportesService.getComparativo(this.propiedades).subscribe(
      (response: any) => {
        let fileName = 'comparativo.xls';
        this.commonService.showLoader();
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(response, fileName);
        } else {
          let dataType = "application/vnd.ms-excel";
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.parentNode.removeChild(downloadLink);
        }
        this.commonService.closeLoader();
      },
      error => {
        var reader = new FileReader();
        reader.onload = function () {
          Swal.fire("Ocurrió un error", JSON.parse(reader.result.toString()).message, "error");
        }
        reader.readAsText(error.error);
        
      });
  }
}
