import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as Constants from '../../appconstants';
import { Propiedad } from 'src/app/components/edicion/Propiedad';

@Injectable({
  providedIn: 'root'
})
export class PropiedadesService {

  constructor(public http: HttpClient) { }

  search(request) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.post<any>(Constants.BASE_URL + "/propiedades/findPropiedades", request, { headers: headers });
  }

  getAll() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get<any>(Constants.BASE_URL + "/propiedades/getAll", { headers: headers });
  }

  get(id:number) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get<any>(Constants.BASE_URL + "/propiedades/getPropiedad?id=" + id, { headers: headers });
  }

  getMax() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get<any>(Constants.BASE_URL + "/propiedades/getMax", { headers: headers });
  }

  newPropiedad(propiedad: any, imageName?:string, imageData?: any) {
    let headers = new HttpHeaders({ 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    const formData = new FormData();
    formData.append('propiedad', JSON.stringify(propiedad));
    formData.append('fileName', imageName);
    formData.append('fileData', imageData);
    return this.http.put(Constants.BASE_URL + "/propiedades/createPropiedad", formData, { headers });
  }

  updatePropiedad(propiedad: any, imageName?:string, imageData?: any) {
    propiedad.fechaActualizacion = null;
    let headers = new HttpHeaders({ 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    const formData = new FormData();
    formData.append('propiedad', JSON.stringify(propiedad));
    formData.append('fileName', imageName);
    formData.append('fileData', imageData);
    return this.http.post(Constants.BASE_URL + "/propiedades/updatePropiedad", formData, { headers });
  }

  editPropiedad(propiedad: any, file: any) {
    let headers = new HttpHeaders({ 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    const formData = new FormData();
    formData.append('propiedad', JSON.stringify(propiedad));
    formData.append('file', file);
    return this.http.put(Constants.BASE_URL + "/propiedades/updatePropiedad", formData, { headers });
  }

}
