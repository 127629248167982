import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService, CommonService } from '../../services/index.services';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  constructor(private router: Router, private loginService:LoginService, private commonService:CommonService) { 
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });

    if(this.loginService.validate()) {
      this.router.navigateByUrl('/home');
    } else {
      this.loginService.clearSession();
    }
  }

  // Realiza la llamada para validar credenciales, coloca en el session storage del navegador el token y los datos del usuario
  login() {
    var user = {"email":this.loginForm.get('email').value, "password":this.loginForm.get('password').value};
    this.commonService.showLoader();
    this.loginService.login(user).subscribe((resp: HttpResponse<any>) => {
      sessionStorage.setItem("token", JSON.stringify(resp.headers.get('Authorization')));
      sessionStorage.setItem("user", JSON.stringify(resp.body.user));
      this.commonService.closeLoader();
      this.commonService.cargaCatalogos();
      this.router.navigateByUrl('/home');
    },
      error => {
        this.commonService.showError(error);
      });
  }

  resetRequest() {
    this.commonService.showLoader();
    this.loginService.resetRequest(this.loginForm.get('email').value).subscribe(resp => {
      this.commonService.showMessage("Se envió un correo para confirmar el reset de la contraseña");
    },
      error => {
        this.commonService.showError(error);
      });
  }
}
