import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent, LoginComponent, BusquedaComponent, EdicionComponent, CatalogosComponent, BitacoraComponent, ReportesComponent, ResetPasswordComponent } from './components/index.components';



const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "home" },
  { path: "home", component: HomeComponent },
  { path: "login", component: LoginComponent },
  { path: "busqueda", component: BusquedaComponent },
  { path: "edicion", component: EdicionComponent },
  { path: "catalogos", component: CatalogosComponent },
  { path: "bitacora", component: BitacoraComponent },
  { path: "reportes", component: ReportesComponent },
  { path: "reset/:key", component: ResetPasswordComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
