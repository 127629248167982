import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import * as Constants from '../../appconstants';

@Injectable({
  providedIn: 'root'
})
export class CatalogosService implements OnInit {

  usuarios: any;
  corredores: any;
  clases: any;
  estatus: any;
  alcaldias: any;
  exclusividades: any;
  multiusuarios: any;

  constructor(public http: HttpClient) { }

  ngOnInit(): void {
  }

  cargaCatalogosAdmin() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });

    let usuariosUrl = Constants.BASE_URL + "/catalogs/getUsuarios";
    let corredoresUrl = Constants.BASE_URL + "/catalogs/getCorredores";
    let clasesUrl = Constants.BASE_URL + "/catalogs/getClases"
    let estatusUrl = Constants.BASE_URL + "/catalogs/getEstatus";
    let alcaldiasUrl = Constants.BASE_URL + "/catalogs/getAlcaldias";
    let exclusividadesUrl = Constants.BASE_URL + "/catalogs/getExclusividades";
    let multiusuariosUrl = Constants.BASE_URL + "/catalogs/getMultiusuarios";

    let usuarios = this.http.get(usuariosUrl, { headers: headers, observe: "response" });
    let corredores = this.http.get(corredoresUrl, { headers: headers, observe: "response" });
    let clases = this.http.get(clasesUrl, { headers: headers, observe: "response" });
    let estatus = this.http.get(estatusUrl, { headers: headers, observe: "response" });
    let alcaldias = this.http.get(alcaldiasUrl, { headers: headers, observe: "response" });
    let exclusividades = this.http.get(exclusividadesUrl, { headers: headers, observe: "response" });
    let multiusuarios = this.http.get(multiusuariosUrl, { headers: headers, observe: "response" });

    return forkJoin(usuarios
      , corredores
      , clases
      , estatus
      , alcaldias
      , exclusividades
      , multiusuarios
    );
  }

  cargaCatalogos() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });

    let corredoresUrl = Constants.BASE_URL + "/catalogs/getCorredores";
    let clasesUrl = Constants.BASE_URL + "/catalogs/getClases"
    let estatusUrl = Constants.BASE_URL + "/catalogs/getEstatus";
    let alcaldiasUrl = Constants.BASE_URL + "/catalogs/getAlcaldias";
    let exclusividadesUrl = Constants.BASE_URL + "/catalogs/getExclusividades";
    let multiusuariosUrl = Constants.BASE_URL + "/catalogs/getMultiusuarios";

    let corredores = this.http.get(corredoresUrl, { headers: headers, observe: "response" });
    let clases = this.http.get(clasesUrl, { headers: headers, observe: "response" });
    let estatus = this.http.get(estatusUrl, { headers: headers, observe: "response" });
    let alcaldias = this.http.get(alcaldiasUrl, { headers: headers, observe: "response" });
    let exclusividades = this.http.get(exclusividadesUrl, { headers: headers, observe: "response" });
    let multiusuarios = this.http.get(multiusuariosUrl, { headers: headers, observe: "response" });

    return forkJoin(corredores
      , clases
      , estatus
      , alcaldias
      , exclusividades
      , multiusuarios
    );
  }

  getBitacora() {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':JSON.parse(sessionStorage.getItem("token"))});
    return this.http.get(Constants.BASE_URL + "/catalogs/getBitacora", { headers: headers, observe: "response" });
  }

  createUsuario(request: any) {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':JSON.parse(sessionStorage.getItem("token"))});
    return this.http.put<any>(Constants.BASE_URL + "/catalogs/createUsuario", request, { headers: headers });
  }

  updateUsuario(request: any) {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':JSON.parse(sessionStorage.getItem("token"))});
    return this.http.post<any>(Constants.BASE_URL + "/catalogs/updateUsuario", request, { headers: headers });
  }

  createCorredor(request: any) {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':JSON.parse(sessionStorage.getItem("token"))});
    return this.http.put<any>(Constants.BASE_URL + "/catalogs/createCorredor", request, { headers: headers });
  }

  updateCorredor(request: any) {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':JSON.parse(sessionStorage.getItem("token"))});
    return this.http.post<any>(Constants.BASE_URL + "/catalogs/updateCorredor", request, { headers: headers });
  }

  createExclusividad(request: any) {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':JSON.parse(sessionStorage.getItem("token"))});
    return this.http.put<any>(Constants.BASE_URL + "/catalogs/createExclusividad", request, { headers: headers });
  }

  updateExclusividad(request: any) {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':JSON.parse(sessionStorage.getItem("token"))});
    return this.http.post<any>(Constants.BASE_URL + "/catalogs/updateExclusividad", request, { headers: headers });
  }

}
