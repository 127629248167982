import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { CatalogosService } from '../catalogos/catalogos.service';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  broadcaster = new EventEmitter<any>();

  catalogosCargados: boolean = false;

  constructor(private catalogosService:CatalogosService) { }

  cargaCatalogos() {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if(!this.catalogosCargados) {

      if(user && user.role == 'ADMIN') {
        this.catalogosService.cargaCatalogosAdmin().subscribe((results:any) => {
          this.catalogosService.usuarios = results[0].body;
          this.catalogosService.corredores = results[1].body;
          this.catalogosService.clases = results[2].body;
          this.catalogosService.estatus = results[3].body;
          this.catalogosService.alcaldias = results[4].body;
          this.catalogosService.exclusividades = results[5].body;
          this.catalogosService.multiusuarios = results[6].body;
          this.broadcast("cargaCatalogos", { result: true });
          this.catalogosCargados = true;
        }, error => {
          this.broadcast("cargaCatalogos", { result: false, error: error });
          this.catalogosCargados = false;
        });
      } else if(user) {
        this.catalogosService.cargaCatalogos().subscribe((results:any) => {
          this.catalogosService.corredores = results[0].body;
          this.catalogosService.clases = results[1].body;
          this.catalogosService.estatus = results[2].body;
          this.catalogosService.alcaldias = results[3].body;
          this.catalogosService.exclusividades = results[4].body;
          this.catalogosService.multiusuarios = results[5].body;
          this.broadcast("cargaCatalogos", { result: true });
          this.catalogosCargados = true;
        }, error => {
          this.broadcast("cargaCatalogos", { result: false, error: error });
          this.catalogosCargados = false;
        });
      } 
    }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms));
  }

  mustMatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  broadcast(channel:string, body:any) {
    this.broadcaster.emit({channel:channel, body:body});
  }

  showLoader() {
    Swal.fire({
      title: 'Procesando',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
  }

  closeLoader() {
    Swal.close();
  }

  showSuccess() {
    Swal.close();
    Swal.fire("Operación exitosa", "", "success");
  }

  showMessage(message:string) {
    Swal.close();
    Swal.fire(message);
  }

  showError(error) {
    Swal.close();
    Swal.fire("Ocurrió un error", error.error.message, "error");
  }

  showWarning(title, message) {
    Swal.close();
    Swal.fire(title, message, "warning");
  }

  showImage(url) {
    Swal.fire({
      width: 1200,
      imageUrl: url,
    })
  }

  closeModal(modalId: String, forma?: FormGroup) {
    if(forma) {
      forma.reset();
    }
    $('#' + modalId).modal('hide');
  }

  openModal(modalId: String) {
    $('#' + modalId).modal('show');
  }

}
