import { formatCurrency, formatNumber } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { faBinoculars, faDownload, faEnvelope, faFileArchive, faFileExport, faImage, faIndustry, faMapMarked, faPhone, faUserTag } from '@fortawesome/free-solid-svg-icons';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CatalogosService, CommonService, PropiedadesService, ReportesService, LoginService, FilesService } from 'src/app/services/index.services';
import Swal from 'sweetalert2';
import * as Constants from '../../appconstants';
import { Propiedad } from '../edicion/Propiedad';

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.css']
})
export class BusquedaComponent implements OnInit {

  faFileExport = faFileExport;
  faImage = faImage;
  faDownload = faDownload;
  faFileArchive = faFileArchive;
  faPhone = faPhone;
  faMapMarked = faMapMarked;
  faEnvelope = faEnvelope;
  faIndustry = faIndustry;
  faUserTag = faUserTag;
  faBinoculars = faBinoculars;

  usuarios: any;
  corredores: any;
  clases: any;
  estatus: any;
  alcaldias: any;
  exclusividades: any;
  multiusuarios: any;
  loadingFotos: boolean = true;
  loadingAdjuntos: boolean = true;
  vistaTabla: boolean = false;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  resultados: any = [];
  propiedad: any = { alcaldia: [], clase: {}, corredor: {}, multiusuario: {}, estatus: {}, exclusividad: [], caracteristicas: {}, instalaciones: {} };

  maxTotalR: number = 10000000;
  maxTotalD: number = 10000000;
  maxDisponible: number;
  minDisponible: number;
  maxRentable: number;
  minRentable: number

  busquedaForm: FormGroup;

  formatMoney(number) {
    if (number) {
      return formatCurrency(number, 'en-US', '$');
    } else {
      return '';
    }
  }

  formatArea(number) {
    if (number) {
      return formatNumber(number, 'en-US');
    } else {
      return '';
    }
  }


  constructor(
    private loginService: LoginService,
    private commonService: CommonService,
    private catalogosService: CatalogosService,
    private filesService: FilesService,
    private propiedadesService: PropiedadesService,
    private reportesService: ReportesService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {

    // $('.modal').on('shown.bs.modal', function (e) {
    //   $('html').addClass('freezePage'); 
    //   $('body').addClass('freezePage');
    // });
    // $('.modal').on('hidden.bs.modal', function (e) {
    //   $('html').removeClass('freezePage');
    //   $('body').removeClass('freezePage');
    // });

    if (!this.loginService.validate()) {
      this.loginService.logout();
    }

    this.busquedaForm = this.formBuilder.group({
      nombre: new FormControl(''),
      clave: new FormControl(''),
      propietario: new FormControl(''),
      direccion: new FormControl(''),
      minDisponible: new FormControl(0),
      maxDisponible: new FormControl(0),
      minRentable: new FormControl(0),
      maxRentable: new FormControl(0),
      corredor: new FormControl([]),
      clase: new FormControl([]),
      estatus: new FormControl(0),
      alcaldia: new FormControl([]),
      exclusividad: new FormControl(0),
    });

    this.dtOptions = {
      scrollX: true,
      pageLength: 5,
      order: [[0, "asc"]],
      lengthMenu: [[5, 25, 50, 100], [5, 25, 50, 100]],
      language: Constants.DT_LANGUAGE
    };

    this.commonService.showLoader();
    this.propiedadesService.getMax().subscribe(res => {
      this.maxTotalR = res.total;
      this.maxTotalD = res.disponible;
      this.busquedaForm.controls['maxDisponible'].setValue(this.maxTotalD);
      this.busquedaForm.controls['maxRentable'].setValue(this.maxTotalR);
      this.commonService.closeLoader();
    },
      error => {
        this.commonService.showError(error);
      });

    if (this.commonService.catalogosCargados) {
      this.cargaCatalogos();
    } else {
      this.commonService.broadcaster.subscribe(message => {
        if (message.channel == "cargaCatalogos") {
          if (message.body.result) {
            this.cargaCatalogos();
          } else {
            this.commonService.showError(message.body.error);
          }
        }
      },
        error => {
          this.commonService.showError(error);
        });
    }
  }

  cargaCatalogos() {
    this.corredores = this.catalogosService.corredores;
    this.clases = this.catalogosService.clases;
    this.estatus = this.catalogosService.estatus;
    this.alcaldias = this.catalogosService.alcaldias;
    this.exclusividades = this.exclusividades = this.catalogosService.exclusividades;
    this.multiusuarios = this.catalogosService.multiusuarios;
  }

  toggleCollapse(element: string) {
    $(element).collapse('toggle');
  }

  hideCollapse(element: string) {
    $(element).collapse('hide');
  }

  clavePad(id) {
    return ('000' + id).substr(-4);
  }

  joinExclusividad(exclusividad) {
    return exclusividad.map(function (ex) { return ex.nombre }).join(",");
  }

  getMinDisponible() {
    this.minDisponible = this.busquedaForm.controls['minDisponible'].value;
    if (this.minDisponible > this.maxDisponible) {
      this.busquedaForm.controls['maxDisponible'].setValue(this.minDisponible);
    }
    return this.minDisponible;
  }

  setMinDisponible(event) {
    this.minDisponible = event.target.valueAsNumber;
    this.busquedaForm.controls['minDisponible'].setValue(this.minDisponible);
  }

  getMaxDisponible() {
    this.maxDisponible = this.busquedaForm.controls['maxDisponible'].value;
    return this.maxDisponible;
  }

  setMaxDisponible(event) {
    this.maxDisponible = event.target.valueAsNumber;
    this.busquedaForm.controls['maxDisponible'].setValue(this.maxDisponible);
  }

  getMinRentable() {
    this.minRentable = this.busquedaForm.controls['minRentable'].value;
    if (this.minRentable > this.maxRentable) {
      this.busquedaForm.controls['maxRentable'].setValue(this.minRentable);
    }
    return this.minRentable;
  }

  setMinRentable(event) {
    this.minRentable = event.target.valueAsNumber;
    this.busquedaForm.controls['minRentable'].setValue(this.minRentable);
  }

  getMaxRentable() {
    this.maxRentable = this.busquedaForm.controls['maxRentable'].value;
    return this.maxRentable;
  }

  setMaxRentable(event) {
    this.maxRentable = event.target.valueAsNumber;
    this.busquedaForm.controls['maxRentable'].setValue(this.maxRentable);
  }

  newSearch() {
    this.busquedaForm = this.formBuilder.group({
      nombre: new FormControl(''),
      clave: new FormControl(''),
      propietario: new FormControl(''),
      direccion: new FormControl(''),
      minDisponible: new FormControl(0),
      maxDisponible: new FormControl(0),
      minRentable: new FormControl(0),
      maxRentable: new FormControl(0),
      corredor: new FormControl([]),
      clase: new FormControl([]),
      estatus: new FormControl(0),
      alcaldia: new FormControl([]),
      exclusividad: new FormControl(0),
    });
    this.busquedaForm.controls['maxDisponible'].setValue(this.maxTotalD);
    this.busquedaForm.controls['maxRentable'].setValue(this.maxTotalR);
    this.vistaTabla = false;
    this.resultados = [];
    this.rerender();
  }

  search() {
    this.commonService.showLoader();
    let formObj = this.busquedaForm.getRawValue();
    this.propiedadesService.search(formObj).subscribe(res => {
      if (res.length > 0) {
        this.resultados = res;
        this.rerender();
        this.commonService.closeLoader();
      } else {
        this.resultados = [];
        this.rerender();
        this.commonService.showMessage("No se encontraron resultados");
      }
    },
      error => {
        this.commonService.showError(error);
      });
  }

  rerender(): void {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  addReportElement(propiedad: any) {

    try {
      this.reportesService.addPropiedad(propiedad);
      this.commonService.broadcast("reportes", { result: true, action: 'add', propiedad: propiedad });
      Swal.fire({
        position: 'top-end',
        type: 'success',
        title: 'Propiedad agregada',
        showConfirmButton: false,
        toast: true,
        timer: 1500
      });
    } catch (error) {
      Swal.fire({
        position: 'top-end',
        type: 'error',
        title: error.message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  downloadFile(id: string, tipo: string, fileName: string) {
    this.commonService.showLoader();
    this.filesService.download(this.clavePad(id), tipo, fileName).subscribe(
      (response: any) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(response, fileName);
        } else {
          let dataType = "application/octet-stream";
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.parentNode.removeChild(downloadLink);
        }
        this.commonService.closeLoader();
      },
      error => {
        this.commonService.showError(error);
      });
  }

  previewFile(id: string, tipo: string, fileName: string) {
    this.commonService.showLoader();
    this.filesService.preview(this.clavePad(id), tipo, fileName).subscribe(
      (response: any) => {
        this.commonService.closeLoader();
        let base64Image = "data:image/png;base64, " + response.data;
        Swal.fire({
          width: 900,
          confirmButtonText: 'Cerrar',
          confirmButtonColor: '#DB2A25',
          html: "<img src='" + base64Image + "' style='width:100%;'>",
        })


      },
      error => {
        this.commonService.showError(error);
      });
  }

  downloadZip(id: string, tipo: string) {
    let fileName = this.clavePad(id) + '-' + tipo + '.zip';
    this.commonService.showLoader();
    this.filesService.downloadAll(this.clavePad(id), tipo).subscribe(
      (response: any) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(response, fileName);
        } else {
          let dataType = "application/zip";
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.parentNode.removeChild(downloadLink);
        }
        this.commonService.closeLoader();
      },
      error => {
        this.commonService.showError(error);
      });
  }

  closeDetailModal() {
    // this.hideCollapse('.collapse');
    // this.commonService.closeModal("detailModal");
    this.propiedad = { alcaldia: [], clase: {}, corredor: {}, multiusuario: {}, estatus: {}, exclusividad: [], caracteristicas: {}, instalaciones: {} };
  }

  viewChange() {
    this.vistaTabla = !this.vistaTabla;
    if(this.vistaTabla) {
      this.rerender();
    }
  }

  openDetailModal(propiedad) {

    propiedad.pisos.sort((a, b) => {
      if ( a.nivel < b.nivel ){
        return -1;
      }
      if ( a.nivel > b.nivel ){
        return 1;
      }
      return 0;
    });

    this.loadingFotos = true;
    this.filesService.getFiles(this.clavePad(propiedad.id), "FOTOS").subscribe(
      (res: any) => {
        this.loadingFotos = false;
        propiedad.fotos = res;
      },
      error => {
        this.loadingFotos = false;
        this.commonService.showError(error);
      });

    this.loadingAdjuntos = true;
    this.filesService.getFiles(this.clavePad(propiedad.id), "DOCS").subscribe(
      (res: any) => {
        this.loadingAdjuntos = false;
        propiedad.adjuntos = res;
      },
      error => {
        this.loadingAdjuntos = false;
        this.commonService.showError(error);
      });
    this.propiedad = propiedad;
    $("#detailModal").animate({ scrollTop: 0 }, "fast");
    // this.commonService.openModal("detailModal");
  }

  getReporteBusqueda() {
    this.commonService.showLoader();
    this.reportesService.getReporteBusqueda(this.resultados).subscribe(
      (response: any) => {
        let fileName = 'Busqueda.xlsx';
        this.commonService.showLoader();
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(response, fileName);
        } else {
          let dataType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.parentNode.removeChild(downloadLink);
        }
        this.commonService.closeLoader();
      },
      error => {
        this.commonService.showError(error);
      });
  }

}
