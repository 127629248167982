import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService, LoginService } from 'src/app/services/index.services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  key: string;
  title: string = "";
  message: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.commonService.showLoader();
    this.key = this.route.snapshot.paramMap.get('key');
    this.loginService.resetPass(this.key).subscribe(resp => {
      Swal.fire("Operación exitosa", "", "success").then((result) => {
        this.router.navigateByUrl('/login');
      });
    },
      error => {
        this.commonService.showError(error);
        this.router.navigateByUrl('/login');
      });
  }

}
