export const BASE_URL='https://aws.areabrc.com:8443';
// export const BASE_URL='http://192.168.100.2:8080';

export const DT_LANGUAGE: DataTables.LanguageSettings = {
    processing: "Procesando...",
    search: "Buscar:",
    lengthMenu: "Mostrar _MENU_ registros",
    info: "Mostrando la página _PAGE_ de _PAGES_",
    infoEmpty: "Mostrando  0 registros",
    infoFiltered: "(filtrado de _MAX_ registros)",
    infoPostFix: "",
    loadingRecords: "Cargando...",
    zeroRecords: "No se encontraron resultados",
    emptyTable: "Ningún dato disponible en esta tabla",
    paginate: {
      first: "Primero",
      previous: "Anterior",
      next: "Siguiente",
      last: "Último",
    },
    aria: {
      sortAscending: ": Activar para ordenar la columna de manera ascendente",
      sortDescending: ": Activar para ordenar la columna de manera descendente"
    }
  }