import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Constants from '../../appconstants';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  changePassword(request: any) {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':JSON.parse(sessionStorage.getItem("token"))});
    return this.http.post<any>(Constants.BASE_URL + "/user/changePass", request, { headers: headers });
  }

  resetPassword(request: any) {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':JSON.parse(sessionStorage.getItem("token"))});
    return this.http.post<any>(Constants.BASE_URL + "/user/resetPass", request, { headers: headers });
  }

}
