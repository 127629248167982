import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService, UserService, CommonService } from '../../services/index.services';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  user: any;

  passwordForm: FormGroup;

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private commonService: CommonService,
    private formBuilder: FormBuilder) { 
      this.user = {nombre:"",apellidoPaterno:"",apellidoMaterno:""};
    }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      actualPass: new FormControl('', [Validators.required, Validators.minLength(6)]),
      newPass: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPass: new FormControl('', Validators.required),
    }, { validator: this.commonService.mustMatchValidator('newPass', 'confirmPass') });

    if (this.loginService.validate()) {
      this.user = JSON.parse(sessionStorage.getItem("user"));
    } else {
      this.loginService.logout();
    }
  }

  changePassword() {
    this.commonService.showLoader();
    let request = {
      "actualPass": this.passwordForm.get('actualPass').value,
      "newPass": this.passwordForm.get('newPass').value,
      "confirmPass": this.passwordForm.get('confirmPass').value
    };
    this.userService.changePassword(request).subscribe(resp => {
      this.commonService.showSuccess();
      this.closePasswordModal();
    },
      error => {
        this.commonService.showError(error);
      });
  }
  
  closePasswordModal() {
    this.commonService.closeModal('passwordModal',this.passwordForm);
  }

}
