import { Injectable } from '@angular/core';
import * as Constants from '../../appconstants';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(public http: HttpClient) { }

  download(id: string, tipo: string, fileName: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get(Constants.BASE_URL + "/files/downloadFile?id=" + id + "&tipo=" + tipo + "&fileName=" + fileName, { headers, responseType: 'blob' as 'json' });
  }

  downloadAll(id: string, tipo: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get(Constants.BASE_URL + "/files/downloadAll?id=" + id + "&tipo=" + tipo , { headers, responseType: 'blob' as 'json' });
  }

  upload(formData: FormData) {
    let headers = new HttpHeaders({ 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.put(Constants.BASE_URL + "/files/uploadFile", formData, { headers });
  }

  getFiles(id: string, tipo: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get(Constants.BASE_URL + "/files/listFiles?id=" + id + "&tipo=" + tipo, { headers: headers });
  }

  delete(id: string, tipo: string, fileName: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.delete(Constants.BASE_URL + "/files/deleteFile?id=" + id + "&tipo=" + tipo + "&fileName=" + fileName, { headers: headers });
  }

  preview(id: string, tipo: string, fileName: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get(Constants.BASE_URL + "/files/previewFile?id=" + id + "&tipo=" + tipo + "&fileName=" + fileName, { headers: headers });
  }

}
