export interface Caracteristicas {
    id: number;
    anioConstruccion: number;
    anioRemodelacion: number;
    nivelesAltura: number;
    nivelesOficinas: number;
    nivelesComerciales: number;
    nivelesEstacionamiento: number;
    sotanos: number;
    cajones: number;
    radioEstacionamiento: string;
    fechaDisponibilidad: string;
    descripcionEspacios: string;
}

export interface Instalaciones {
    id: number;
    aireAcondicionado: string;
    subestacionElectrica: string;
    plantaEmergencias: string;
    cctv: string;
    elevadoresPersonas: string;
    elevadoresCarga: string;
    sistemaContraIncendio: string;
    escalerasServicio: string;
    escalerasEmergencia: string;
}

export interface Alcaldia {
    id: number;
    nombre: string;
    descripcion?: any;
    activo: boolean;
}

export interface Corredor {
    id: number;
    nombre: string;
    descripcion: string;
    activo: boolean;
}

export interface Clase {
    id: number;
    nombre: string;
    descripcion: string;
    activo: boolean;
}

export interface Estatus {
    id: number;
    nombre: string;
    descripcion: string;
    activo: boolean;
}

export interface Multiusuario {
    id: number;
    nombre: string;
    descripcion?: any;
    activo: boolean;
}

export interface Exclusividad {
    id: number;
    nombre: string;
    descripcion: string;
    activo: boolean;
}

export interface Piso {
    id: number;
    nivel: string;
    totalUtilizable: number;
    totalRentable: number;
    disponibleUtilizable: number;
    disponibleRentable: number;
    rentaUsd?: any;
    ventaUsd?: any;
    rentaMxn: number;
    ventaMxn?: any;
    mantenimiento: number;
    usuarios: string;
}

export interface Contacto {
    id: number;
    nombre: string;
    empresa: string;
    puesto: string;
    direccion: string;
    colonia: string;
    telefonos: string;
    email: string;
}

export interface Nota {
    id: number;
    nota: string;
    fecha: string;
}

export interface S3File {
    fileName: string;
    url: string;
    modified: string;
    size: string;
}

export class Propiedad {
    id: number;
    nombre: string;
    direccion: string;
    colonia: string;
    cp: string;
    ciudad: string;
    estado: string;
    propietario: string;
    telefonoPropietario: string;
    ocupante: string;
    distribucion: string;
    totalTerreno: number;
    totalConstruccion: number;
    totalRentable: number;
    rentableDisponible: number;
    totalUtil: number;
    fotoUrl: string;
    disponibilidad: string;
    plantaTipo: string;
    fechaActualizacion: string;
    fotos: S3File[];
    adjuntos: S3File[];
    
    caracteristicas: Caracteristicas = {id: null,
        anioConstruccion: null,
        anioRemodelacion: null,
        nivelesAltura: null,
        nivelesOficinas: null,
        nivelesComerciales: null,
        nivelesEstacionamiento: null,
        sotanos: null,
        cajones: null,
        radioEstacionamiento: null,
        fechaDisponibilidad: null,
        descripcionEspacios: null,};

    instalaciones: Instalaciones = {id: null,
        aireAcondicionado: null,
        subestacionElectrica: null,
        plantaEmergencias: null,
        cctv: null,
        elevadoresPersonas: null,
        elevadoresCarga: null,
        sistemaContraIncendio: null,
        escalerasServicio: null,
        escalerasEmergencia: null,};

    alcaldia: Alcaldia;
    corredor: Corredor;
    clase: Clase;
    estatus: Estatus;
    multiusuario: Multiusuario;
    exclusividad: Exclusividad[];
    pisos: Piso[];
    contacto: Contacto[];
    notas: Nota[];
    activo: boolean = true;
}
