import { Component, OnInit } from '@angular/core';
import { faAngleDoubleLeft, faAngleDoubleRight, faClipboard, faEdit, faFileExport, faHome, faNewspaper, faPalette, faSearch, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { CommonService, LoginService, ReportesService } from 'src/app/services/index.services';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { Propiedad } from '../edicion/Propiedad';
import { not } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  cssUrl: string;

  faFileExport = faFileExport;
  faClipboard = faClipboard;
  faHome = faHome;
  faSearch = faSearch;
  faEdit = faEdit;
  faNewspaper = faNewspaper;
  faSignOutAlt = faSignOutAlt;
  faAngleDoubleLeft = faAngleDoubleLeft;
  faAngleDoubleRight = faAngleDoubleRight;
  faPalette = faPalette;

  elementos: number;
  propiedades: Propiedad[] = [];
  styleSheet: CSSStyleSheet;
  opening: boolean = false;

  constructor(
    public sanitizer: DomSanitizer,
    private loginService: LoginService,
    private commonService: CommonService,
    private reportesService: ReportesService,
  ) { }

  ngOnInit() {

    let props: Propiedad[] = JSON.parse(sessionStorage.getItem("propiedades"));
    if(props) {
      this.propiedades = props;
    }

    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = <CSSStyleSheet>style.sheet;

    if (localStorage.getItem("theme") == "dark") {
      this.goDark();
    }

    this.commonService.cargaCatalogos();
    this.commonService.broadcaster.subscribe(message => {
      if (message.channel == "reportes") {
        let props: Propiedad[] = JSON.parse(sessionStorage.getItem("propiedades"));
        this.propiedades = props;
      }
    },
      error => {
        this.commonService.showError(error);
      });
  }

  removePropiedad(id) {
    this.reportesService.removePropiedad(id);
    this.propiedades = JSON.parse(sessionStorage.getItem("propiedades"));
    this.commonService.broadcast("reportes", { result: true, action: 'remove' });
  }

  themeSwitch() {
    Swal.fire({
      title: "Cambio de tema",
      text: "Se perderá cualquier cambio no guardado, ¿Desea continuar?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DB2A25',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        if (localStorage.getItem("theme") == "dark") {
          localStorage.setItem("theme", "light");
        } else {
          localStorage.setItem("theme", "dark");
          // this.goDark();
        }
        location.reload();
      }
    })
  }

  goDark() {
    this.styleSheet.insertRule("thead {background: #cccccc;color: grey;}");
    this.styleSheet.insertRule(".modal-content {background: #101010;}");
    this.styleSheet.insertRule(".close {color:#ffffff;}");
    this.styleSheet.insertRule(".table-striped tbody tr:nth-of-type(odd) {background-color: rgba(255,255,255,.05);}");
    this.styleSheet.insertRule("table {color: #ffffff !important;}");
    this.styleSheet.insertRule(".btn {color: #ffffff;}");
    this.styleSheet.insertRule(".btn:hover {color: #aaaaaa;}");

    this.styleSheet.insertRule(".page-link {background: #666666;}");
    this.styleSheet.insertRule(".page-link {background: #666666;color:#ffffff;}");
    this.styleSheet.insertRule(".page-item.disabled .page-link {background: #666666;}");
    this.styleSheet.insertRule("body {background: #101010;color: #ffffff;}");

    this.styleSheet.insertRule(".jumbotron {color: #212529;}");
    this.styleSheet.insertRule("hr {border: 0.5px solid #888888;}");

    this.styleSheet.insertRule(".card-body {background: #444444;}");
    this.styleSheet.insertRule(".card-body:not(.inner) {background: #272727;}");
    this.styleSheet.insertRule(".card-header, .card-footer {background: grey;}");
    this.styleSheet.insertRule(".card-img-top {background: #444444;}");
    this.styleSheet.insertRule(".text-muted {color: #AAAAAA !important;}");

    this.styleSheet.insertRule(".swal2-popup {background: #666666; !important}");
    this.styleSheet.insertRule(".swal2-title {color: #FFFFFF; !important}");
    this.styleSheet.insertRule(".swal2-content {color: #FFFFFF; !important}");

  }

  logout() {
    this.closeNav();
    this.loginService.logout();
  }

  logged() {
    return this.loginService.validate();
  }

  role(roles: string[]) {
    if (this.logged()) {
      return roles.includes((JSON.parse(sessionStorage.getItem("user"))).role);
    }
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    this.opening = true;
  }

  closeNav() {
    if (this.opening) {
      this.opening = false;
    } else {
      document.getElementById("mySidenav").style.width = "0";
    }
  }
}


