import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpRequest } from '@angular/common/http';
import * as Constants from '../../appconstants';
import { CatalogosService } from '../catalogos/catalogos.service';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private router: Router, 
    private http: HttpClient, 
    private catalogosService: CatalogosService, 
    private commonService: CommonService) { 
  }

  login(user:any) {
    return this.http.post<any>(Constants.BASE_URL + "/auth/login", user, { observe: 'response' });
  }

  resetRequest(email:any) {
    return this.http.get<any>(Constants.BASE_URL + "/auth/resetRequest" + "?email="+encodeURI(email), { observe: 'response' });
  }

  resetPass(key:any) {
    return this.http.post<any>(Constants.BASE_URL + "/auth/resetPass", {key:key}, { observe: 'response' });
  }

  logout() {
    this.clearSession();
    this.router.navigateByUrl('/login');
  }

  clearSession() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
  }

  validate() {
    if (sessionStorage.getItem("token") && sessionStorage.getItem("user")) {
      return true;
    } else {
      return false;
    }
  }
}
