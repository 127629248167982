import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Constants from '../../appconstants';
import { Propiedad } from 'src/app/components/edicion/Propiedad';
import { CommonService } from '../index.services';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {

  constructor(public http: HttpClient) { }

  addPropiedad(propiedad: Propiedad) {
    let props: Propiedad[] = JSON.parse(sessionStorage.getItem("propiedades"));
    if (!props) {
      props = [];
    }
    if (props.length >= 6) {
      throw new Error("Máximo 6 permitidos");
    } else {

      for (var i = 0; i < props.length; i++) {
        if (props[i].id == propiedad.id) {
          throw new Error("Ya se encuentra la propiedad")
        }
      }
      props.push(propiedad);
      sessionStorage.setItem("propiedades", JSON.stringify(props));
    }
  }

  removePropiedad(id) {
    let props: Propiedad[] = JSON.parse(sessionStorage.getItem("propiedades"));
    for (var i = 0; i < props.length; i++) {
      if (props[i].id == id) {
        props.splice(i, 1);
      }
    }
    sessionStorage.setItem("propiedades", JSON.stringify(props));
  }

  getReporteBusqueda(propiedades: any[]) {
    let request: number[] = [];
    propiedades.forEach(propiedad => {
      request.push(propiedad.id);
    });

    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get(Constants.BASE_URL + "/reportes/getListaBusqueda?request=" + encodeURI(JSON.stringify(request)), { headers, responseType: 'blob' as 'json' });
  }

  getPropiedad(id: number, formato: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get(Constants.BASE_URL + "/reportes/getPropiedad?id=" + encodeURI(JSON.stringify(id)) + 
    "&format=" + encodeURI(formato)
    , { headers, responseType: 'blob' as 'json' });
  }

  getPropiedades(propiedades: Propiedad[], formato: string) {
    let ids = '';
    propiedades.forEach(prop => {
      ids += prop.id+",";
    });
    ids.substring(0,ids.length-1);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get(Constants.BASE_URL + "/reportes/getPropiedades?ids=" + encodeURI(JSON.stringify(ids)) + 
    "&format=" + encodeURI(formato)
    , { headers, responseType: 'blob' as 'json' });
  }

  sendPropiedad(id: number, formato: string, correos: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get(Constants.BASE_URL + "/reportes/sendPropiedad?id=" + encodeURI(JSON.stringify(id)) + 
    "&format=" + encodeURI(formato) + 
    "&emails=" + encodeURI(correos)
    , { headers: headers, observe: "response" });
  }

  sendPropiedades(propiedades: Propiedad[], formato: string, correos: string) {
    let ids = '';
    propiedades.forEach(prop => {
      ids += prop.id+",";
    });
    ids.substring(0,ids.length-1);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get(Constants.BASE_URL + "/reportes/sendPropiedades?ids=" + encodeURI(JSON.stringify(ids)) + 
    "&format=" + encodeURI(formato) + 
    "&emails=" + encodeURI(correos)
    , { headers: headers, observe: "response" });
  }

  getComparativo(propiedades: Propiedad[]) {
    let ids = '';
    propiedades.forEach(prop => {
      ids += prop.id+",";
    });
    ids.substring(0,ids.length-1);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem("token")) });
    return this.http.get(Constants.BASE_URL + "/reportes/getComparativo?ids=" + encodeURI(JSON.stringify(ids))
    , { headers, responseType: 'blob' as 'json' });
  }

}
